<template>
	<div class="documents-dashboard__item">
		<ItemHeader
			:pinned="item.pinned"
			:title="item.name"
			:can-edit="userCanEdit"
			@deleteCategory="deleteCategory(item.id)"
			@editPinState="editPinState(item.id, $event)"
			@updateCategoryName="updateCategoryName(item.id, $event)"
		/>

		<List
			v-if="item.knowledges && item.knowledges.length"
			:index="index"
			:data="item.knowledges"
			:can-edit="userCanEdit"
			:is-expanded="isExpanded"
			:has-subcategory="item.subcategories.some(el => el.knowledges.length)"
			@removeExpand="removeExpand"
			@deleteItem="deleteItem(item.id, $event)"
			@onDrop="onDrop(index, $event)"
			@checkItem="checkItem"
		/>

		<div
			v-if="item.subcategories && item.subcategories.length && item.subcategories.some(el => el.knowledges.length)"
			class="documents-dashboard__item-subcategories-wr"
		>
			<div
				v-for="(el, subcategoryIndex) in item.subcategories"
				:key="el.id"
			>
				<template
					v-if="el.knowledges && el.knowledges.length"
				>
					<Subcategory
						:data="el"
						:group-index="index"
						:container-index="subcategoryIndex"
						:user-can-edit="userCanEdit"
						:is-expanded="isExpanded"
						@onSubcategoryDrop="onSubcategoryDrop"
						@deleteSubcategoryItem="deleteSubcategoryItem"
						@deleteSubcategory="deleteSubcategory"
						@checkItem="checkItem"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
// import components
import ItemHeader  from '@/components/documents/dashboard-item/header.vue';
import Subcategory from '@/components/documents/dashboard-item/subcategory.vue';
import List        from '@/components/documents/dashboard-item/list.vue';

export default {
	name: 'DndCard',
	components: { ItemHeader, Subcategory, List },

	props: ['index', 'item', 'userCanEdit', 'isExpanded'],

	methods:
	{
		deleteCategory(id) { this.$emit('deleteCategory', id); },

		editPinState(id, state) { this.$emit('editPinState', { id, state } ); },

		updateCategoryName(id, value) { this.$emit('updateCategoryName', { id, value } ); },

		deleteItem(categoryId, itemId) { this.$emit('deleteItem', { categoryId, itemId } ); },

		onDrop(groupIndex, value) { this.$emit('onDrop', { groupIndex, value }); },

		onSubcategoryDrop(value) { this.$emit('onSubcategoryDrop', value) },

		deleteSubcategoryItem(value) { this.$emit('deleteSubcategoryItem', value) },

		deleteSubcategory(value) { this.$emit('deleteSubcategory', value) },

		removeExpand() { this.$emit('removeExpand') },

		checkItem(value)
		{
			if (value.subcategoryId)
				this.$emit('checkItem', { category: this.item.id, subcategoryId: value.subcategoryId, knowledgeId: value.knowledgeId });
			else
				this.$emit('checkItem', { category: this.item.id, subcategoryId: null, knowledgeId: value });
		},
	},
}
</script>

<style lang="scss" scoped>
	.documents-dashboard__item
	{
		position: relative;
		border-radius: 19px;
		margin: 0 20px 30px 0;
		background: #222222;

		&::before
		{
			content: '';
			position: absolute;
			z-index: -1;
			top: -10px;
			width: 98%;
			border-radius: 19px;
			height: 50px;
			background: #2C2C2C99;
			left: 50%;
			transform: translateX(-50%);
		}

		.documents-dashboard__item-subcategories-wr:last-of-type { padding-bottom: 20px; }
	}

	.documents-dashboard__item-subcategories-wr { padding: 30px 30px 0 30px; }
</style>