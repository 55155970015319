<template>
	<div
		class="documents-dashboard__item-knowledge"
		:class="{'is-new': isNew}"
		@click="link && link != 'empty' ? redirectToLink(link) : $router.push({ path: `/documents/${id}` })"
		@mouseenter="hovered = true"
		@mouseleave="hovered = false"
	>
		<div
			v-if="isExpanded"
			class="documents-dashboard__item-knowledge-checkbox"
			:class="{'active': checked}"
			@click.stop="$emit('checkItem', id)"
		>
			<Icon v-if="checked" name="done" size="10" />
		</div>

		<div class="documents-dashboard__item-knowledge-title">
			{{ title }}
			<div class="span__after">
				<Icon v-if="link && link != 'empty'" name="link" size="10" class="documents-dashboard__item-knowledge-icon" />
				<template v-if="tags && tags.length">
					<div
						v-for="tag in tags"
						:key="tag.id"
						:style="{'background': tag.color}"
						class="documents-dashboard__item-knowledge-tag"
					/>
				</template>
				<div ref="moreDetails" class="documents-dashboard__item-knowledge-options-wr" @click.stop>
					<div
						v-if="canEdit && hovered || showOptions"
						class="documents-dashboard__item-knowledge-options-icon"
						:class="{'active': showOptions }"
						@click.stop="showOptions = !showOptions"
					>
						<Icon name="more" size="14" />
					</div>
					<div
						v-if="showOptions && canEdit"
						v-click-outside="closeOptions"
						class="documents-dashboard__item-knowledge-options"
					>
						<div
							class="documents-dashboard__item-knowledge-options-item"
							@click.stop="$router.push(`/documents/${id}#edit`)"
						>
							<Icon name="edit" size="14" />
							<span class="documents-dashboard__item-knowledge-options-item-text">
								Редактировать
							</span>
						</div>
						<div
							class="documents-dashboard__item-knowledge-options-item documents-dashboard__item-knowledge-options-item--delete"
							@click.stop="showDeleteContext = true, hovered = false, showOptions = false"
						>
							<Icon name="delete" size="14" />
							<span class="documents-dashboard__item-knowledge-options-item-text">
								Удалить
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>



		<DeleteContext v-if="showDeleteContext" @close="closeDeleteContext" @delete="deleteItem">
			<p>
				Удалить знание '{{ title }}'
			</p>
		</DeleteContext>
	</div>
</template>

<script>
// import components
import Icon          from '@/components/ui/icon/Icon.vue';
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';

export default {
	name: 'DocumentDashboardListItem',

	components:
	{
		DeleteContext,
		Icon,
	},

	props:
	{
		id:
		{
			type: Number,
		},
		link:
		{
			type: String,
			default: '',
		},
		title:
		{
			type: String,
			default: '',
		},
		tags:
		{
			type: Array,
			default: () => [],
		},
		isNew:
		{
			type: Boolean,
			default: false,
		},
		canEdit:
		{
			type: Boolean,
			default: false,
		},
		isExpanded:
		{
			type: Boolean,
			default: false,
		},
		checked:
		{
			type: Boolean,
		},
	},

	data()
	{
		return {
			showOptions: false,
			hovered: false,
			showDeleteContext: false,
		};
	},

	methods:
	{
		closeOptions()
		{
			this.showOptions = false;
		},

		closeDeleteContext()
		{
			this.showDeleteContext = false;
		},

		deleteItem()
		{
			this.$emit('deleteItem', this.id)
			this.showDeleteContext = false;
		},

		redirectToLink(val)
		{
			if (val.includes('http://') || val.includes('https://'))
				window.location.href = val;
			else
				window.location.href = 'http://' + val;
		},
	},
}
</script>

<style lang="scss">
	.documents-dashboard__item-knowledge
	{
		cursor: grabbing;
		display: flex;
		align-items: start;
		gap: 10px;

		div, span
		{
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			word-break: break-word;
			color: #80BEEA;
			transition: all .2s ease;
		}

		.span__after
		{
			position: relative;
			display: inline-flex;
			align-items: center;
			flex-wrap: wrap;
			column-gap: 5px;
			top: 2px;
			left: 3px;
			row-gap: 5px;
		}

		.delete-context-wrp { cursor: auto; }

		&:hover span:not(.documents-dashboard__item-knowledge-options-item-text),
		&:hover .documents-dashboard__item-knowledge-title { color: #F7D547; }

		&.is-new
		{
			position: relative;
			&::before
			{
				position: absolute;
				content: "";
				top: 50%;
				transform: translateY(-50%);
				left: -12px;
				width: 6px;
				height: 6px;
				border-radius: 100px;
				background: #F7D547;
			}
		}
	}

	.documents-dashboard__item-knowledge-tag
	{
		width: 14px;
		height: 11px;
		border-radius: 8px;
		background: #2C2C2C;
	}

	.documents-dashboard__item-knowledge-options-wr
	{
		position: relative;
		display: inline-block;
		width: max-content;
	}

	.documents-dashboard__item-knowledge-options-icon
	{
		cursor: pointer;
		height: 14px;
		color:#656565;
		transition: all .2 ease;

		&:hover,
		&.active { color: #FFFFFF; }
	}

	.documents-dashboard__item-knowledge-options
	{
		cursor: auto;
		position: absolute;
		z-index: 30;
		width: max-content;
		padding: 20px;
		background: #191919;
		border: 0.7px solid #FFFFFF33;
		border-radius: 10px;
		top: 25px;
		left: -10px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.documents-dashboard__item-knowledge-options-item
	{
		cursor: pointer;
		display: flex;
		gap: 12px;

		.documents-dashboard__item-knowledge-options-item-text
		{
			font-size: 14px;
			font-weight: 300;
			line-height: 17px;
			color: #FFFFFFCC;
		}

		&:hover
		{
			span { color: #FFFFFF; }
			.icon { color: #FFFFFF; }
		}

		&--delete
		{
			span { color: #9E4835; }
			.icon { color: #9E4835; }

			&:hover
			{
				span { color: red; }
				.icon { color: red; }
			}
		}
	}

	.documents-dashboard__item-knowledge-checkbox {
		cursor: pointer;
		flex-shrink: 0;
		width: 13px;
		height: 13px;
		border-radius: 3px;
		border: 1px solid #FFFFFF80;
		display: flex;
		align-items: center;
		justify-content: center;

		&.active
		{
			background-color: #F7D547;
			border-color: #F7D547;
		}

		.icon { color: black }
	}
</style>