<template>
	<div
		class="dnd-list"
		:class="{'has-subcategories': hasSubcategory}"
	>
		<Container
			:data-index="index"
			group-name="column"
			:get-child-payload="itemIndex => getChildPayload(itemIndex)"
			:should-accept-drop="() => true"
			drag-class="card-ghost-drop"
			@drop="onDrop"
			@drag-start="startDragging"
			:drop-placeholder="dropPlaceholderOptions"
		>
			<Draggable
				v-for="child in data"
				:key="child.id"
			>
				<ListItem
					:checked="child.checked"
					:is-new="child.is_new"
					:id="child.id"
					:link="child.link"
					:title="child.title"
					:tags="child.tags"
					:can-edit="canEdit"
					:is-expanded="isExpanded"
					@deleteItem="deleteItem"
					@checkItem="checkItem"
				/>
			</Draggable>
		</Container>
	</div>
</template>

<script>
// import components
import { Container, Draggable } from 'vue-smooth-dnd';
import ListItem                 from '@/components/documents/dashboard-item/item.vue';


export default {
	name: 'DragAndDropList',

	props: ['data', 'index', 'canEdit', 'hasSubcategory', 'isExpanded'],

	components: { ListItem, Container, Draggable },

	data()
	{
		return {
			dropPlaceholderOptions: {
				className: 'drop-preview',
				animationDuration: '100',
				showOnTop: true,
			},
		}
	},

	methods:
	{
		getChildPayload(itemIndex) { return this.data[itemIndex]; },

		onDrop(event) { this.$emit('onDrop', event) },

		deleteItem(value) { this.$emit('deleteItem', value); },

		checkItem(value) { this.$emit('checkItem', value); },

		startDragging() { this.$emit('removeExpand') },
	},
}
</script>

<style lang="scss">
	.dnd-list
	{
		padding: 30px;
		.smooth-dnd-container { position: relative; }
		.smooth-dnd-draggable-wrapper { padding: 7.5px 0; overflow: visible!important; }
		&.has-subcategories { padding-bottom: 0; }
	}

	.card-ghost-drop
	{
		padding: 8px 12px;
		background: #2C4170;
		border-radius: 8px;
		color: #FFFFFF;
	}

	.drop-preview
	{
		margin: 5px 0;
		background: #2C4170;
		border-radius: 8px;
		color: #FFFFFF;
	}
</style>